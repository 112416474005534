<script setup>
import { useLayout } from "@/hooks/useLayout";
import { menus } from "./views/shared/menus";
const { toggleSidebar, toggleSubMenu } = useLayout();
</script>
<template>
  <div class="mobile_header hidden--desktop">
    <div class="mobile_header__backdrop"></div>
    <div class="mobile_header__container">
      <div class="mobile_header__container__site_branding">
        <div
          id="block-site-branding-mobile"
          class="block block-system block-system-branding-block block--site-branding-mobile"
        >
          <div class="wrapper">
            <a class="mobile_header__logo" href="/" title="Home" rel="home">
              <img
                src="/img/logo-mini.png"
                alt="Home"
                width="736"
                height="146"
              />
            </a>

            <div class="mobile_header__actions">
              <a href="/donate" class="donate">Donate</a>
              <div class="mobile_header__actions__search icon-search"></div>
              <div
                class="mobile_header__actions__hamburger icon-hamburger"
                @click="toggleSidebar"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile_header__container__panel">
        <div class="mobile_header__container__panel__content">
          <div class="mobile_header__container__panel__content--top">
            <a
              class="mobile_header__container__panel__content--top__logo"
              href="/"
              style="font-weight: 700; color: #fff; font-size: 1.722rem"
            >
              World Light Life
            </a>
            <div class="icon-close" @click="toggleSidebar"></div>
          </div>
          <div class="mobile_header__container__panel__search">
            <form
              class="mobile_header__container__panel__search__form"
              action="/search"
            >
              <div>
                <a
                  class="mobile_header__container__panel__search__icon icon-search"
                  href="/search"
                ></a>
                <input
                  class="mobile_header__container__panel__search__input"
                  type="text"
                  name="search"
                  value=""
                  placeholder="SEARCH WVI.ORG"
                />
              </div>
            </form>
          </div>
          <div class="mobile_header__container__panel__menu">
            <nav
              role="navigation"
              aria-labelledby="block-main-navigation-mobile-menu"
              id="block-main-navigation-mobile"
              class="block block-menu navigation menu--main region-topnav__menu--main"
            >
              <h2
                class="visually-hidden"
                id="block-main-navigation-mobile-menu"
              >
                Main navigation
              </h2>

              <div class="container">
                <ul class="menu menu-level-0">
                  <li
                    v-for="menu in menus"
                    :key="menu.title.split(' ').join('_')"
                    class="menu-item menu-item--expanded menu-item--who-we-are"
                  >
                    <div class="menu_link_title">
                      <RouterLink v-if="!menu.subs" :to="menu.to">{{
                        menu.title
                      }}</RouterLink>
                      <a
                        href="javascript:void(0)"
                        @click.prevent="toggleSubMenu"
                        v-else
                        >{{ menu.title }}</a
                      >
                      <div class="menu_link_title_chevron"></div>
                      <div
                        class="menu-dropdown-wrapper menu-dropdown"
                        v-if="menu.subs"
                      >
                        <button
                          class="back_button icon-chevron-left"
                          @click="toggleSubMenu"
                        >
                          BACK
                        </button>
                        <div class="container">
                          <div
                            class="menu_link_content menu-link-contentmain view-mode-default menu-dropdown menu-dropdown-0 menu-type-default"
                          >
                            <ul class="menu menu-level-1">
                              <div class="menu_title">{{ menu.title }}</div>
                              <li
                                class="menu-item menu-item--expanded"
                                v-for="sub in menu.subs"
                                :key="sub.title.split(' ').join('__')"
                              >
                                <RouterLink :to="sub.to">{{
                                  sub.title
                                }}</RouterLink>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <div class="mobile_header__container__panel__get_involved">
            <nav
              role="navigation"
              aria-labelledby="block-get-involved-mobile-menu"
              id="block-get-involved-mobile"
              class="block block-menu navigation menu--get-involved"
            >
              <h2 class="visually-hidden" id="block-get-involved-mobile-menu">
                Get Involved
              </h2>

              <ul class="menu-get-involved">
                <li class="menu-item--donate">
                  <RouterLink
                    :to="{ name: 'Donate' }"
                    class="btn btn-sm btn-primary action-call w-100"
                    >Donate</RouterLink
                  >
                </li>
                <li class="menu-item--get-involved">
                  <RouterLink
                    :to="{ name: 'GetInvolved' }"
                    class="btn btn-sm btn-outline-primary action-call w-100"
                    >Get Involved</RouterLink
                  >
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
a:not(.action-call),
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
}
.menu--get-involved {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  position: relative;
}
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
.menu-get-involved {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
}
.menu-get-involved .menu-item--donate,
.menu-get-involved .menu-item--get-involved {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
@media (max-width: 71.25rem) {
  .mobile_header {
    background-color: #336bbb;
    position: fixed;
    width: 100%;
    z-index: 100;
    top: 0;
  }
  .hidden--desktop {
    display: block;
  }
  .mobile_header__backdrop {
    content: "";
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #333;
    opacity: 0.5;
    top: 0;
    left: 0;
    z-index: 100;
    display: none;
  }
  .mobile_header__container__panel {
    overflow: hidden;
    position: fixed;
    width: 20rem;
    height: 100vh;
    height: calc((var(--vh, 1vh) * 100));
    top: 0;
    left: -20rem;
    z-index: 100;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  .mobile_header__container__panel__content {
    width: 100%;
    height: 100%;
    background-color: #fff;
  }
  html {
    font-size: 100%;
  }
  html.panel-opened {
    overflow: hidden;
  }
  html.panel-opened .mobile_header__container__panel {
    left: 0;
  }
  .mobile_header__container__panel__content--top {
    background-color: #336bbb;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 4.375rem;
    padding: 0 1rem;
  }

  .mobile_header__container__panel__content--top img {
    height: 2.5rem;
  }

  .mobile_header__container__panel__content--top .icon-close {
    color: #fff;
    font-weight: 600;
    cursor: pointer;
  }
  .mobile_header__container__panel__menu {
    height: calc((var(--vh, 1vh) * 100) - 12.063rem);
    overflow: hidden;
  }
  .visually-hidden {
    position: absolute !important;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    word-wrap: normal;
  }

  h2 {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .mobile_header__container__panel__menu > nav > .container,
  .mobile_header__container__panel__menu > nav > .container .container {
    padding: 0;
  }

  ol,
  ul {
    list-style: none;
  }

  .mobile_header__container__panel__menu
    > nav
    > .container
    > ul.menu-level-0
    > li.menu-item--expanded
    > .menu_link_title {
    position: relative;
  }

  .mobile_header__container__panel__menu
    > nav
    > .container
    > ul.menu-level-0
    > li
    > .menu_link_title {
    border-top: 0.063rem solid #b3b2b2;
  }
  .mobile_header__container__panel__menu
    > nav
    > .container
    > ul.menu-level-0
    > li.menu-item--expanded
    > .menu_link_title:before {
    position: absolute;
    right: 1rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1.5rem;
    height: 1.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: 600;
    color: #333;
    font-size: 0.75rem;
  }
  .mobile_header__container__panel__menu
    > nav
    > .container
    > ul.menu-level-0
    > li
    > .menu_link_title
    a {
    display: block;
    width: 81%;
    font-size: 0.875rem;
    font-weight: 900;
    line-height: 3.5rem;
    letter-spacing: 0.125rem;
    color: #333;
    padding: 0 1rem;
    position: relative;
  }

  .mobile_header__container__panel__get_involved {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 101;
    background-color: #fff;
  }
  .mobile_header__container__panel__get_involved > nav {
    width: auto;
    padding: 0.625rem 1rem;
  }
  .mobile_header__container__panel__search {
    height: 3.75rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 1rem;
    background-color: #f4f4f4;
  }
  .mobile_header__container__panel__search__form {
    width: 100%;
  }
  .mobile_header__container__panel__search__form > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .mobile_header__container__panel__search__form > div a {
    margin-right: 1.25rem;
    width: 1rem;
    display: block;
    color: #000;
  }
  input,
  textarea {
    -webkit-appearance: none;
    border: none;
    vertical-align: top;
    margin: 0;
    max-width: 100%;
    font-family: Lato, Helvetica, sans-serif;
    font-size: 1rem;
  }
  :focus {
    outline: none;
  }
  .mobile_header__container__panel__search__form > div input {
    padding: 0;
    height: 100%;
    background: unset;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 4.375rem;
    letter-spacing: 0.125rem;
    width: calc(100% - 2.25rem);
  }
  .mobile_header__container__site_branding {
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  .mobile_header__container__site_branding .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.938rem 0;
    height: 4.375rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .mobile_header__logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .mobile_header__container__site_branding .wrapper .mobile_header__logo img {
    width: 5.725rem;
    height: auto;
    margin-left: 5px;
  }
  .mobile_header__container__site_branding .wrapper .mobile_header__actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    gap: 1rem;
    margin-right: 10px;
  }
  .mobile_header__actions .donate {
    background-image: none;
    border-radius: 0.25rem;
    border-style: solid;
    border-width: 0.125rem;
    cursor: pointer;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 1.125rem;
    font-family: Lato, Helvetica, sans-serif;
    font-weight: 700;
    line-height: 1.5rem;
    padding: 0.875rem;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-decoration: none;
    text-transform: capitalize;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
  }

  .btn--small,
  .mobile_header__actions .donate {
    font-size: 1rem;
    font-weight: 400;
    min-width: inherit;
    padding: 0.125rem 0.875rem;
  }

  .mobile_header__actions .donate {
    background-color: #fff;
    border-color: #fff;
    color: #336bbb;
    min-width: 5.938rem;
  }
  .mobile_header__container__site_branding
    .wrapper:not(.use_branding)
    .mobile_header__actions__search {
    display: none;
  }
  .mobile_header__container__site_branding
    .wrapper
    .mobile_header__actions__hamburger:before {
    font-size: 1.375rem;
    display: block;
    font-weight: 600;
  }
}
.mobile_header__container__panel__menu
  > nav
  > .container
  > ul.menu-level-0
  > li.menu-item--expanded
  .menu-dropdown-wrapper {
  position: absolute;
  width: 100%;
  right: -100%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  top: 4.375rem;
  height: calc(100vh - 4.375rem);
  background-color: #fff;
  z-index: 100;
}
.mobile_header .back_button {
  width: 100%;
  padding: 1rem 1rem 0;
  text-align: left;
  border: 0;
  background: unset;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 900;
  line-height: 1.5rem;
  letter-spacing: 0.125rem;
}
.mobile_header__container__panel__menu
  > nav
  > .container
  > ul.menu-level-0
  > li.menu-item--expanded
  .menu-dropdown-wrapper
  > .container
  > div
  > ul.menu-level-1 {
  padding: 1rem;
  border-bottom: 1px solid #b3b2b2;
  max-height: calc(100vh - 10.813rem - 80px);
  max-height: calc((var(--vh, 1vh) * 100) - 10.813rem);
  overflow: auto;
}
.mobile_header .menu_title {
  padding-left: 2.063rem;
  padding-right: 3.75rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.688rem;
  color: #333;
}
.mobile_header__container__panel__menu
  > nav
  > .container
  > ul.menu-level-0
  > li.menu-item--expanded
  .menu-dropdown-wrapper
  > .container
  > div
  > ul.menu-level-1
  > li {
  padding-left: 2.063rem;
  padding-right: 3.75rem;
  border: 0;
}
.mobile_header__container__panel__menu
  > nav
  > .container
  > ul.menu-level-0
  > li.menu-item--expanded
  .menu-dropdown-wrapper
  > .container
  > div
  > ul.menu-level-1
  > li
  > a {
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.5rem;
  color: #333;
  display: block;
  padding: 0;
  background-color: unset;
}
.mobile_header__container__panel__menu
  > nav
  > .container
  > ul.menu-level-0
  > li.menu-item--expanded
  .menu-dropdown-wrapper
  > .container
  > div
  > ul.menu-level-1
  > li.menu-item--expanded
  > a {
  position: relative;
  padding: 0;
}
.mobile_header__container__panel__menu
  > nav
  > .container
  > ul.menu-level-0
  > li.menu-item--expanded.active
  .menu-dropdown-wrapper,
.mobile_header__container__panel__menu
  > nav
  > .container
  > ul.menu-level-0
  > li.menu-item--expanded
  .menu-dropdown-wrapper
  > .container
  > div
  > ul.menu-level-1
  > li.menu-item--expanded.active
  > .menu_link_content {
  right: 0;
}
html.panel-opened .mobile_header__backdrop {
  display: block;
}
</style>
