<script setup>
import { nextTick, onMounted, ref } from "vue";
import "plyr/dist/plyr.css";

const modal = ref();
onMounted(() => {
  nextTick(() => {
    modal.value = new window.bootstrap.Modal(
      document.getElementById("youtube-video-modal")
    );
  });
});
function show() {
  modal.value.show();
}
function hide() {
  modal.value.hide();
}
defineExpose({ show, hide });
</script>
<template>
  <div
    class="modal fade"
    id="youtube-video-modal"
    role="dialog"
    tabindex="-1"
    style="padding-right: 15px; padding-left: 0px"
  >
    <div
      class="modal-dialog modal-md modal-xl modal-dialog-centered"
      role="document"
    >
      <div
        class="modal-content rounded-xl bg-dark shadow-primary-xs border border-dark border-3"
      >
        <button
          type="button"
          @click="hide"
          style="
            margin-top: -18px;
            margin-right: -14px;
            width: 36px;
            height: 36px;
            z-index: 1;
          "
          class="border-0 d-flex align-items-center justify-content-center pointer position-absolute top-0 end-0 text-white bg-dark rounded-circle z-index-100"
        >
          <svg
            height="18px"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
            ></path>
            <path
              fill-rule="evenodd"
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
            ></path>
          </svg>
        </button>
        <div class="embed-responsive embed-responsive-16by9">
          <iframe
            class="embed-responsive-item rounded-xl"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            width="560"
            height="315"
            frameborder="0"
            id="video-iframe"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
