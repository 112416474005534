import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { adminRoutes } from "./admin.routes";
import { appRoutes } from "./app.routes";

const routes: Array<RouteRecordRaw> = [...adminRoutes, ...appRoutes];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

const token = localStorage.getItem("token");
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (token) {
      next();
      return;
    }
    location.replace("/");
  } else {
    next();
  }
});

router.afterEach((to) => {
  if (typeof to.meta.title === "string") {
    document.title = `${to.meta.title} | World Light Family`;
  }
  const megaMenu = document.querySelector("#navbarNavDropdown");
  const toggler = document.querySelector("[aria-controls='navbarNavDropdown']");
  if (megaMenu) {
    megaMenu.classList.remove("show");
    toggler && toggler.classList.add("collapsed");
  }
  document.documentElement.classList.remove("panel-opened");
  const el = document.querySelector(".menu-item--expanded.active");
  if (el) {
    const li = el.closest("li");
    if (li) {
      li.classList.remove("active");
    }
  }
});

export default router;
