import { ref } from "vue";
import Plyr from "plyr";

const player = ref<Plyr>();
const playingMode = ref(false);

function initializePlayer(video: string) {
  const iframe = document.getElementById("video-iframe") as HTMLIFrameElement;
  if (iframe) {
    iframe.src = `https://www.youtube.com/embed/${video}`;
    iframe.allowFullscreen = true;
    player.value = new Plyr(iframe, {
      controls: [
        "play-large",
        "play",
        "progress",
        "current-time",
        "mute",
        "volume",
        "fullscreen",
      ],
      autoplay: !0,
      invertTime: !1,
    });
  }
}

function getVideoId(videoUrl: string): string {
  if (videoUrl.indexOf("youtu.be") !== -1) {
    return videoUrl.split("/")[1].slice(-1);
  }
  const result = videoUrl.split("?v=")[1];
  return result;
}

function watchVideo(video: string) {
  playingMode.value = false;
  if (player.value) {
    player.value.destroy();
  }
  const videoId = getVideoId(video);
  initializePlayer(videoId);
  playingMode.value = true;
}

export function useVideos() {
  return { watchVideo, getVideoId, playingMode };
}
