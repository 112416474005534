export const appRoutes = [
  {
    path: "/",
    name: "HomePage",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/index/views/HomeView.vue"),
    meta: {
      title: "Welcome!",
    },
  },
  {
    path: "/about-us/who-we-are",
    name: "AboutPage",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/index/views/AboutView.vue"),
    meta: {
      title: "About Us",
    },
  },
  {
    path: "/about-us/our-work",
    name: "OurWorkPage",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/index/views/OurWork.vue"),
    meta: {
      title: "Our Work",
    },
  },
  {
    path: "/about-us/our-approach",
    name: "OurApproach",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/index/views/OurApproach.vue"),
    meta: {
      title: "Our Approach",
    },
  },
  {
    path: "/get-involved",
    name: "GetInvolved",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/index/views/GetInvolved.vue"),
    meta: {
      title: "Get Involved",
    },
  },
  {
    path: "/contact-us",
    name: "ContactView",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/index/views/ContactView.vue"),
    meta: {
      title: "Contact Us",
    },
  },
  {
    path: "/about-us/gallery",
    name: "GalleryView",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/index/views/GalleryView.vue"),
    meta: {
      title: "Gallery",
    },
  },
  {
    path: "/about-us/history",
    name: "OurHistory",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/index/views/OurHistory.vue"),
    meta: {
      title: "Our History",
    },
  },
  {
    path: "/events",
    name: "EventsView",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/index/views/EventsView.vue"),
    meta: {
      title: "Events",
    },
  },
  {
    path: "/events/:slug",
    name: "EventDetails",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/index/views/EventDetails.vue"),
    meta: {
      title: "Events",
    },
  },
  {
    path: "/support/faq",
    name: "FAQView",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/index/views/FAQView.vue"),
    meta: {
      title: "Frequently Asked Questions",
    },
  },
  {
    path: "/support/legal",
    name: "LegalView",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/index/views/LegalView.vue"),
    meta: {
      title: "Legal, Terms & Conditions and Privacy Policy",
    },
  },
  {
    path: "/donate",
    name: "Donate",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/index/views/DonateView.vue"),
    meta: {
      title: "Support Our Mission",
    },
  },
];
