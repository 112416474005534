type menu = {
  title: string;
  to?: { name: string };
  subs?: menu[];
};
export const menus: menu[] = [
  { title: "Home", to: { name: "HomePage" } },
  {
    title: "About Us",
    subs: [
      { title: "Who We Are", to: { name: "AboutPage" } },
      { title: "Our History", to: { name: "OurHistory" } },
      { title: "Our Work", to: { name: "OurWorkPage" } },
      { title: "Our Approach", to: { name: "OurApproach" } },
      { title: "Gallery", to: { name: "GalleryView" } },
    ],
  },
  { title: "Events", to: { name: "EventsView" } },
  { title: "Contact Us", to: { name: "ContactView" } },
  { title: "FAQs", to: { name: "FAQView" } },
];
